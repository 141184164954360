import { call, select, put } from 'redux-saga/effects';
import api from '../utils/api';
import { fetchErrorHandler } from '../actions/fetchErrorHandler';
import { receiveAdvertisementProduct } from '../actions/advertisement';
import { getUser } from '../reducers/user';

export default function* fetchAdvertisementProduct() {
  try {
    const { cookies } = yield select(getUser);
    const { data } = yield call(api(cookies).get, `/advertisement/`);

    yield put(receiveAdvertisementProduct({ products: data || null }));
  } catch (error) {
    yield put(fetchErrorHandler(error.response, 'Something went wrong'));
  }
}
