export const ADVERTISEMENT_PRODUCT = {
  request: 'ADVERTISEMENT_PRODUCT_REQUEST',
  success: 'ADVERTISEMENT_PRODUCT_SUCCESS',
};

export const requestAdvertisementProduct = () => ({
  type: ADVERTISEMENT_PRODUCT.request,
});
export const receiveAdvertisementProduct = ({ products }) => ({
  type: ADVERTISEMENT_PRODUCT.success,
  payload: { products },
});
